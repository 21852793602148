import React from 'react';
import { Container } from 'reactstrap';
import { Badge } from 'reactstrap';
import { withRouter } from 'react-router';

const RightArticle = (props) => {
  const { data } = props;
  return (
    <Container className="article__wrapper">
      <div className="article article__right">
        <div className="article__content">
          { data.title &&
            <h2 className="article__title">
              {data.title}
            </h2>
          }
          { data.id &&
            <a id={data.id} name={data.id} className="services-anchor" key={Math.random().toString(36).substring(2, 5) + Math.random().toString(36).substring(2, 5)}>
            </a>
          }
          { data.body &&
            <div className="article__body">
              {data.body}
            </div>
          }
          { data.links && 
            <div className="links">
              {data.links.map((link, index) => (
                <a key={Math.random().toString(36).substring(2, 5) + Math.random().toString(36).substring(2, 5)} href={link.url}>
                  <Badge color="primary" pill>
                    {link.title}
                  </Badge>
                </a>
              ))}
            </div>
          }
        </div>
        { data.img.src && 
          <div className={data.img.className ? 'article__image ' + data.img.className : 'article__image'}>
            <img src={data.img.src} alt={data.img.alt} />
          </div>
        }
      </div>
    </Container>
  );
}

export default withRouter(RightArticle);
