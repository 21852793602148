import React from 'react';
import { post } from 'axios';
import { Col, Button, Form, FormGroup, Label, Input, Container, Alert } from 'reactstrap';
import PhoneInput from 'react-phone-number-input';

class ContactUs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      phone: '',
      company: '',
      description: '',
      response: '',
      error: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({value: event.target.value});
  }

  handleSubmit(event) {
    event.preventDefault();
    const sendData = {};
    sendData.name = this.state.name;
    sendData.email = this.state.email;
    sendData.phone = this.state.phone;
    sendData.company = this.state.company;
    sendData.description = this.state.description;
    post('/handler.php', sendData)
      .then(res => {
        this.setState({ response: res });
      }).catch(err => {
        this.setState({ error: err });
      });
  }

  render() {
    const {
      response,
      name,
      email,
      phone,
      company,
      description,
      error,
    } = this.state;
    return (
      <Container className="container__form" style={{backgroundImage: "url('assets/images/brick-wall.jpg')", backgroundSize: "cover", backgroundRepeat: "no-repeat"}}>
        <Form onSubmit={this.handleSubmit}>
          <FormGroup row>
            <Label for="name" sm={2}>Name:</Label>
            <Col sm={10}>
              <Input
                required
                bsSize="lg" 
                type="text"
                name="name"
                placeholder="Name"
                value={name}
                onChange={e => this.setState({ name: e.target.value })}
              />  
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="company" sm={2}>Company:</Label>
            <Col sm={10}>
              <Input
                required
                bsSize="lg" 
                type="text"
                name="company"
                placeholder="Company"
                value={company}
                onChange={e => this.setState({ company: e.target.value })}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="email" sm={2}>Email:</Label>
            <Col sm={10}>
              <Input
                required
                bsSize="lg" 
                type="email"
                name="email"
                placeholder="Email"
                value={email}
                onChange={e => this.setState({ email: e.target.value })}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="phone" sm={2}>Phone:</Label>
            <Col sm={10}>
              <PhoneInput
                required
                name="phone"
                placeholder="Phone Number"
                defaultCountry="US"
                value={phone}
                onChange={e => this.setState({ phone: e })}
              />
            </Col>
          </FormGroup>
          <FormGroup row style={{padding: "15px"}}>
            <Label for="description">Description of Inquiry:</Label>
            <Input
              required
              bsSize="lg" 
              type="textarea"
              rows={5}
              name="description"
              placeholder="description"
              value={description}
              onChange={e => this.setState({ description: e.target.value })}
            />
          </FormGroup>
          { error && (
            <Alert color="danger" className="contact-us--alert">
              Form Submission failed to send. Please reload the page and try again, or email <a href="mailto:info@conticorplabs.com" className="alert-link">info@conticorplabs.com</a>
            </Alert>
          )}
          {response === '' ? (
            <Button className="contact-us--button" type="submit" color="primary" >Submit</Button>
          ) : (
            <Alert color="success" className="contact-us--alert">Form submitted Successfully</Alert>
          )}
        </Form>
      </Container>
    );
  }
}

export default ContactUs;